import { Container, Divider } from "@material-ui/core";
import axios from "axios";
import { ModalError, ModalSuccess, Page, SkeletonComponent } from "components";
import { HeaderTitle } from "layouts";
import { useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractOriginalPublisherForm from "./Components/ContractOriginalPublisherForm";

function AddContract() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);

  const handlSubmitData = async ({
    modifiedPayload,
    supportingDocumentFiles,
  }) => {
    const formData = new FormData();
    formData.append("contract_number", modifiedPayload?.contract_number);
    formData.append("song_ids", modifiedPayload?.song_ids);
    formData.append("date_periode", Number(modifiedPayload?.date_periode));
    formData.append("start_date", modifiedPayload?.start_date);
    formData.append("end_date", modifiedPayload?.end_date);
    formData.append("description", modifiedPayload?.description);
    formData.append("documents", supportingDocumentFiles[0]);

    try {
      setLoadingPage(true);
      await axios.post(
        `${hardBaseUrl}/publisher/contract/original-publisher`,
        supportingDocumentFiles ? formData : modifiedPayload,
        { headers }
      );
      ModalSuccess("Successfully Create Contract").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Page className={classes.root} title="Add Contract Original Publisher">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Add Contract"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <ContractOriginalPublisherForm handlSubmitData={handlSubmitData} />
        </Container>
      )}
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Original Publisher",
    link: "/admin/kontrak-original-publisher",
  },
  {
    label: "Add Contract",
    active: true,
  },
];

export default AddContract;
