import {
  Card,
  Checkbox,
  Chip,
  Container,
  Divider,
  IconButton,
  Box,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import TrashVector from "../../../../assets/img/trashVector.svg";
import {
  CurrencyNumberFormLayout,
  ModalError,
  NumberFormLayout,
  SingleFormLayout,
  SwitchFormLayout,
  TextAreaLayout,
  Page,
  PrimaryButton,
  SecondaryButton,
  FormLabel,
  DatePicker,
  DateRangePicker,
  MonthYearRangePicker,
  SelectInput,
  AutoCompleteComponent,
  TextInput,
  SkeletonComponent,
  CurrencyNumberInput,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  ModalAddSong,
  ModalBulkAdd,
  ModalSongResults,
  ModalUploadResults,
} from "./ModalInvoice";
import { Grid } from "@mui/material";
import { HeaderTitle } from "layouts";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  pageSubTitle: {
    margin: "18px 0",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  searchIcon: {
    color: "#9AA2B1",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  required: {
    color: "red",
  },
  formContainer: {
    padding: 20,
    marginTop: 10,
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  formTitle: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
  },
  buttonGridContainer: {
    marginTop: 10,
  },
  tableContainer: {
    margin: "12px 0",
  },
  tableCheckboxContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  tableCheckbox: {
    "&.MuiCheckbox-root": {
      color: "#111827",
      "&.Mui-checked": {
        color: "#111827",
      },
    },
  },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  buttonWhite: {
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
    padding: "6px 8px",
  },
  buttonDisabled: {
    backgroundColor: "lightgray",
    color: "white",
    textTransform: "none",
    "&.MuiButton-root.Mui-disabled": {
      color: "gray",
    },
  },
  loadingButton: {
    color: "white",
    margin: "0 10px",
  },
  totalFeeLabel: {
    textAlign: "end",
  },
  flexSpaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalRevenueLabel: { fontWeight: "bold", fontSize: 24 },
  currencyLabel: { fontSize: 18 },
  taxesContainer: {
    borderRadius: "6px",
    boxShadow: "none",
    backgroundColor: "#fafafa",
    padding: "14px",
    marginTop: "28px",
  },
  buttonDeleteContainer: { display: "flex", justifyContent: "center" },
  buttonDelete: {
    backgroundColor: "#111827",
    color: "white",
    padding: "8px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
}));

function FormInvoice({
  pageTitle,
  pageSubTitle,
  loadingPage,
  preload,
  onSubmitPayload,
  editMode,
}) {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const listInvoiceType = [
    { key: "claim", name: "Claim" },
    { key: "usage", name: "Usage" },
  ];

  const [payload, setPayload] = useState({
    transaction_name: "claim",
    type: "usage",
    claim_type: "claim",
    invoice_number: "",
    transaction_number: "",
    po_number: "",
    invoice_date: moment(),
    dsp_id: 0,
    end_date: moment(),
    start_date: moment(),
    exchange_rate: 0,
    job_number: "",
    account_number: 0,
    is_all_song: false,
    songs: [],
    revenue: 0,
    note: "",
    taxes: [],
  });
  const [optionClient, setOptionClient] = useState([]);
  const [optionBankAccount, setOptionBankAccount] = useState([]);
  const [showTaxes, setShowTaxes] = useState(false);
  const [showModalBulkAdd, setShowModalBulkAdd] = useState(false);
  const [showModalAddSong, setShowModalAddSong] = useState(false);
  const [showModalSongResults, setShowModalSongResult] = useState(false);
  const [showModalUploadResults, setShowModalUploadResults] = useState(false);
  const [uploadedSong, setUploadedSong] = useState({});

  const isUsageInvoice = payload?.type === "usage";
  const payloadDspId = payload?.dsp_id;
  let selectedDSP = optionClient?.find(dsp => dsp.dsp_id === payloadDspId);
  const selectedTaxes = payload?.taxes;
  const checkSelectedTaxes = selectedTaxes?.length > 0;
  const dataTableClaim = {
    note: payload?.note,
    revenue: payload?.revenue,
  };
  const isOverseas = selectedDSP && selectedDSP?.domicile === "overseas";
  const isForeignCurrency =
    selectedDSP &&
    selectedDSP?.dsp_currency !== null &&
    selectedDSP?.dsp_currency?.currency_iso_code !== "IDR";
  const selectedCurrencyCode = `${selectedDSP?.dsp_currency
    ?.currency_symbol_code || "Rp "} `;
  let totalRevenue = calculateTotalRevenue(payload?.songs);
  let totalTax = calculateTotalTax(selectedTaxes, totalRevenue);
  let totalFee = isUsageInvoice ? totalRevenue : payload?.revenue;
  let totalRevenueAfterTax = totalFee + totalTax;
  const initialPayload = useRef(payload);
  const hasPayloadChanges =
    JSON.stringify(initialPayload?.current) !== JSON.stringify(payload);
  const hasChanges = !editMode ? hasPayloadChanges : false;

  const handleCloseModalBulkAdd = () => setShowModalBulkAdd(false);
  const handleCloseModalAddSong = () => setShowModalAddSong(false);
  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleAddSong = item => {
    const modifiedTable = item?.map(item => {
      const revenueValue =
        typeof item?.revenue === "string" && item?.revenue_number !== undefined
          ? Number(item?.revenue_number)
          : Number(item?.revenue);

      return {
        ...item,
        revenue: revenueValue,
        qty: 1,
      };
    });

    handleChangePayload(modifiedTable, "songs");
    handleCloseModalAddSong();
  };
  const handleChangeCheckboxTaxes = (event, item) => {
    const { checked } = event.target;
    const newSelectedTaxes = checked
      ? [...selectedTaxes, item]
      : selectedTaxes.filter(selected => selected.id !== item.id);
    handleChangePayload(newSelectedTaxes, "taxes");
  };
  const setPreload = () => {
    if (preload && preload.period) {
      const [startPeriod, endPeriod] = preload?.period?.split(" - ");
      let start_date, end_date;

      start_date = formatPreloadDate(startPeriod);
      end_date = formatPreloadDate(endPeriod);

      const modifiedPreload = {
        ...preload,
        start_date,
        end_date,
        invoice_number: preload?.transaction_number,
        invoice_date: formatPreloadDate(preload?.invoice_date),
      };
      setPayload(modifiedPreload);
    }
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    if (payload?.dsp_id === 0) {
      errors.push(errorEmpty("Client Name"));
    } else if (payload?.songs?.length === 0 && isUsageInvoice) {
      errors.push(errorEmpty("Songs"));
    } else {
      Object.entries(payload).forEach(([key, value]) => {
        switch (key) {
          case "revenue":
            if (!value && !isUsageInvoice) errors.push(errorEmpty("Fee"));
            break;
          case "invoice_date":
            if (!value || value === "1970-01-01") {
              errors.push("Invoice date");
            } else if (
              value &&
              new Date(value).toString() === "Invalid Date" &&
              value !== "1970-01-01"
            ) {
              errors.push("Invoice Date - Invalid format");
            }
            break;
          case "invoice_number":
            if (!value) errors.push(errorEmpty("Invoice number"));
            break;
          case "account_number":
            if (!value && isOverseas) {
              errors.push(errorEmpty("Account number"));
            }
            break;
          case "job_number":
            if (!value && isOverseas) {
              errors.push(errorEmpty("Job number"));
            }
            break;
          case "start_date":
            if (!value) {
              if (!errors.includes("Period")) errors.push(errorEmpty("Period"));
            }
            break;
          case "end_date":
            if (!value) {
              if (!errors.includes("Period")) errors.push(errorEmpty("Period"));
            }
            break;
          case "taxes":
            if (value.length && showTaxes) errors.push(errorEmpty("Taxes"));
            break;
          case "songs":
            if (value.length && isUsageInvoice) {
              value.forEach((data, index) => {
                if (data.revenue === "0" || !data.revenue) {
                  errors.push(errorEmpty(`Song fee number ${index + 1}`));
                } else if (!data.qty) {
                  errors.push(errorEmpty(`Song qty number ${index + 1}`));
                }
              });
            }
            break;
          default:
            break;
        }
      });
    }

    return errors;
  };

  const CurrencyFormatted = ({ value, currencyCode, isTotal, isTable }) => (
    <NumberFormat
      displayType="text"
      className={
        isTable
          ? ""
          : isTotal
          ? classes?.totalRevenueLabel
          : classes?.currencyLabel
      }
      value={value || 0}
      thousandSeparator={true}
      decimalScale={!isTable && 2}
      fixedDecimalScale={true}
      suffix={!isTable && ",-"}
      prefix={currencyCode}
    />
  );

  const RenderComposerChip = item => {
    const composerList = item?.composers;
    return (
      <Grid container spacing={3}>
        {composerList?.length &&
          composerList?.map(composer => {
            const composerId = composer?.composer_id;
            const composerName = composer?.sure_name;
            return (
              <Grid item key={composerId}>
                <Tooltip
                  key={composerId}
                  title={composerName}
                  placement="bottom"
                >
                  <Chip
                    label={composerName}
                    className={classes?.tableChip}
                    size="small"
                  />
                </Tooltip>
              </Grid>
            );
          })}
      </Grid>
    );
  };
  const RenderPublihserPercentageTable = item => {
    const composerList = item?.composers;
    const selectedSongId = item?.song_id;
    return (
      <Grid container>
        {composerList?.length &&
          composerList?.map(composer => {
            const composerId = composer?.composer_id;
            const composerName = composer?.sure_name;
            const composerPercentage = composer?.publisher_share_percentage;
            return (
              <Tooltip key={composerId} title={composerName} placement="bottom">
                <Grid item>
                  <NumberFormLayout
                    value={composerPercentage}
                    handleChangeValue={val => {
                      {
                        const modifiedTable = payload?.songs?.map(song =>
                          song?.song_id === selectedSongId
                            ? {
                                ...song,
                                composers: song?.composers?.map(composer =>
                                  composer?.composer_id === composerId
                                    ? {
                                        ...composer,
                                        publisher_share_percentage: Number(val),
                                      }
                                    : composer
                                ),
                              }
                            : song
                        );
                        handleChangePayload(modifiedTable, "songs");
                      }
                    }}
                    customProps="%"
                  />
                </Grid>
              </Tooltip>
            );
          })}
      </Grid>
    );
  };
  const RenderTableCurrency = (value, currencyCode) => (
    <CurrencyFormatted value={value} currencyCode={currencyCode} isTable />
  );
  const RenderCurrencyInput = (value, key, isUsage) => {
    const selectedId = value?.song_id;
    return (
      <CurrencyNumberFormLayout
        value={value?.[key]}
        handleChangeValue={val => {
          const modifiedTable = payload?.songs?.map(song =>
            song.song_id === selectedId ? { ...song, [key]: Number(val) } : song
          );
          if (isUsage) {
            handleChangePayload(modifiedTable, "songs");
          } else {
            handleChangePayload(Number(val), key);
          }
        }}
        currecnyPrefix={selectedCurrencyCode}
      />
    );
  };
  const RenderNumberInput = (value, key) => {
    const selectedId = value?.song_id;
    return (
      <NumberFormLayout
        value={value?.[key]}
        handleChangeValue={val => {
          const modifiedTable = payload?.songs?.map(song =>
            song.song_id === selectedId ? { ...song, [key]: Number(val) } : song
          );
          handleChangePayload(modifiedTable, "songs");
        }}
      />
    );
  };
  const RenderTextArea = (value, key, isUsage) => {
    const selectedId = value?.song_id;
    return (
      <TextAreaLayout
        value={value?.[key]}
        onChange={event => {
          const val = event?.target?.value;
          const modifiedTable = payload?.songs?.map(song =>
            song?.song_id === selectedId ? { ...song, [key]: val } : song
          );
          if (isUsage) {
            handleChangePayload(modifiedTable, "songs");
          } else {
            handleChangePayload(val, key);
          }
        }}
        placeholder="Note"
      />
    );
  };
  const RenderDeleteRow = value => {
    const selectedId = value?.song_id;
    return (
      <div className={classes?.buttonDeleteContainer}>
        <IconButton
          className={classes?.buttonDelete}
          onClick={() => {
            const modifiedTable = payload?.songs?.filter(
              selected => selected.song_id !== selectedId
            );
            handleChangePayload(modifiedTable, "songs");
          }}
        >
          <img alt="theDataImage" src={TrashVector} />
        </IconButton>
      </div>
    );
  };
  const RenderTableCheckbox = item => (
    <div className={classes?.tableCheckboxContainer}>
      <Checkbox
        className={classes?.tableCheckbox}
        checked={selectedTaxes?.some(selected => selected?.id === item?.id)}
        onChange={e => handleChangeCheckboxTaxes(e, item)}
      />
    </div>
  );

  const getClientList = async () => {
    const url = `${hardBaseUrl}/master/all-client`;
    try {
      const res = await axios.get(url, { headers });
      const { data } = res?.data;
      const modifiedData = [
        {
          id: 0,
          label: "None",
        },
        ...data?.map(item => ({
          ...item,
          id: item?.dsp_id,
          label: item?.dsp_name,
        })),
      ];
      setOptionClient(modifiedData);
    } catch (error) {
      ModalError(error);
    }
  };
  const getExchangeRate = async () => {
    const currentDate = moment().format("DD MMM YYYY");
    const dspCurrencyId = selectedDSP?.dsp_currency?.id;
    const countryCurrencyId = selectedDSP?.country?.currency?.id;
    const currencyId = dspCurrencyId || countryCurrencyId;
    const url = `${hardBaseUrl}/exchange-rate/code?currency_id=${currencyId}&upload_date=${currentDate}`;
    if (currencyId) {
      try {
        const res = await axios.get(url, { headers });
        const resValue = res?.data?.data?.value;
        handleChangePayload(Number(resValue), "exchange_rate");
      } catch (error) {
        ModalError(error);
      }
    }
  };
  const getListBankAccount = async () => {
    const url = `${hardBaseUrl}/publisher/bank`;
    try {
      const res = await axios.get(url, { headers });
      const resData = res?.data?.data;
      if (res?.data?.meta?.http_status === 200) {
        setOptionBankAccount(resData);
      }
    } catch (error) {
      ModalError(error);
    }
  };
  const postUploadDocuments = item => {
    handleCloseModalBulkAdd();
    const formData = new FormData();
    const url = `${hardBaseUrl}/publisher/transaction-song/upload`;
    formData.append("file", item[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(url, formData, config)
      .then(res => {
        const resData = res?.data?.data;
        const validSongs = resData?.valid_songs;
        const processSongs = validSongs.map(item => {
          const uniqueComposers = [...new Set(item.composers)];
          const composerObjects = uniqueComposers.map(composer => ({
            sure_name: composer,
          }));

          return {
            ...item,
            composers: composerObjects,
            qty: 1,
          };
        });

        const mergeResult = [...payload?.songs, ...processSongs];
        const modifiedTable = Array.from(
          new Set(mergeResult.map(song => song?.song_id))
        ).map(song_id => mergeResult.find(song => song?.song_id === song_id));

        handleChangePayload(modifiedTable, "songs");
        setUploadedSong(resData);
        setShowModalUploadResults(true);
      })
      .catch(() => {
        ModalError("Document failed to upload");
      });
  };
  const handleSubmitPayload = () => {
    const errors = validatePayload();

    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }

    const modifiedPayload = {
      ...payload,
      taxes: selectedTaxes?.map(tax => tax?.id),
      transaction_number: payload?.invoice_number,
      invoice_date: formatPayloadDate(payload?.invoice_date),
      start_date: isUsageInvoice
        ? formatPayloadDate(payload?.start_date)
        : formatPayloadPeriodClaim(payload?.start_date),
      end_date: isUsageInvoice
        ? formatPayloadDate(payload?.end_date)
        : formatPayloadPeriodClaim(payload?.end_date),
    };

    onSubmitPayload(modifiedPayload);
  };

  useEffect(() => {
    getClientList();
    getListBankAccount();
  }, []);
  useEffect(() => {
    setPreload();
  }, [preload]);
  useEffect(() => {
    getExchangeRate();
  }, [payloadDspId]);
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);
  useEffect(() => {
    const unblock = history.block(() => {
      if (hasChanges) {
        return "You have unsaved changes. Are you sure you want to leave this page?";
      }
    });

    return () => {
      unblock();
    };
  }, [history, hasChanges]);

  const columnTableSongDetail = [
    ...(isUsageInvoice
      ? [
          {
            name: "song_title",
            title: "Song",
          },
          {
            name: "all",
            title: "Composer/Author",
            renderText: item => RenderComposerChip(item),
          },
          {
            name: "all",
            title: "Publisher Percentage",
            renderText: item => RenderPublihserPercentageTable(item),
          },
          {
            name: "type",
            title: "Type",
          },
          {
            name: "all",
            title: "Notes",
            renderText: item => RenderTextArea(item, "notes", true),
          },
          {
            name: "all",
            title: (
              <>
                Fee <span className={classes?.required}>*</span>
              </>
            ),
            renderText: item => RenderCurrencyInput(item, "revenue", true),
          },
          {
            name: "all",
            title: "Qty",
            renderText: item => RenderNumberInput(item, "qty"),
          },
          {
            name: "all",
            title: "Total Fee",
            renderText: item =>
              RenderTableCurrency(
                Number(item?.revenue) * Number(item?.qty),
                selectedCurrencyCode
              ),
          },
          {
            name: "all",
            title: "Action",
            renderText: item => RenderDeleteRow(item),
          },
        ]
      : [
          {
            name: "all",
            title: (
              <>
                Fee <span className={classes?.required}>*</span>
              </>
            ),
            renderText: item => RenderCurrencyInput(item, "revenue"),
          },
          {
            name: "all",
            title: "Notes",
            renderText: item => RenderTextArea(item, "note"),
          },
        ]),
  ];
  const buttonAddList = [
    ...(isEmptyObject(uploadedSong)
      ? []
      : [
          {
            label: "Results",
            onClick: () => setShowModalSongResult(!showModalSongResults),
          },
        ]),
    {
      label: "Bulk Add",
      onClick: () => setShowModalBulkAdd(!showModalBulkAdd),
    },
    {
      label: "Single Add",
      onClick: () => setShowModalAddSong(!showModalAddSong),
    },
  ];
  const buttonPageActionList = [
    {
      label: "Cancel",
      onClick: () => history.push("/admin/invoice"),
      secondary: true,
    },
    {
      label: editMode ? "Save" : "Add",
      onClick: handleSubmitPayload,
      disabled: loadingPage,
      loading: loadingPage,
    },
  ];

  return (
    <Page className={classes?.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Invoice"
            breadcrumbData={breadcrumbData(pageTitle)}
          />
          <Divider className={classes?.divider} />
          <Typography className={classes?.pageSubTitle}>
            {pageSubTitle}
          </Typography>
          <Grid container mb={2}>
            <Grid item xs={12} md={4}>
              <FormLabel label="Invoice Type" />
              <SelectInput
                value={payload?.type}
                onChange={event =>
                  handleChangePayload(event?.target?.value, "type")
                }
                options={listInvoiceType}
                optionKey="key"
                optionLabel="name"
                width={150}
              />
            </Grid>
          </Grid>
          <Card className={classes?.formContainer}>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Invoice Number" required />
                <TextInput
                  placeholder="Invoice Number"
                  value={payload?.invoice_number}
                  onChange={event =>
                    handleChangePayload(event?.target?.value, "invoice_number")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="PO Number" />
                <TextInput
                  placeholder="PO Number"
                  value={payload?.po_number}
                  onChange={event =>
                    handleChangePayload(event?.target?.value, "po_number")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Invoice Date" required />
                <DatePicker
                  value={payload?.invoice_date}
                  onChange={date => handleChangePayload(date, "invoice_date")}
                  minDate={preload ? null : moment()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Client Name" required />
                <AutoCompleteComponent
                  options={optionClient}
                  value={
                    optionClient.find(option => option.id === payloadDspId) ||
                    null
                  }
                  onChange={value => handleChangePayload(value, "dsp_id")}
                  size="small"
                  width="100%"
                />
              </Grid>
              {!editMode && (
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <PrimaryButton
                      label="Add Client"
                      endIcon={<AddIcon />}
                      onClick={() =>
                        history.push("/admin/parameter/client/add", {
                          fromClaim: true,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormLabel label="Usage Period" required />
                {isUsageInvoice ? (
                  <DateRangePicker
                    startDate={payload?.start_date}
                    endDate={payload?.end_date}
                    handleChangeStartDate={value =>
                      handleChangePayload(value, "start_date")
                    }
                    handleChangeEndDate={value =>
                      handleChangePayload(value, "end_date")
                    }
                    width="100%"
                    placeholder="Usage Period"
                    endMaxDate={moment().add(10, "years")}
                  />
                ) : (
                  <MonthYearRangePicker
                    startDate={payload?.start_date}
                    endDate={payload?.end_date}
                    handleChangeStartDate={value =>
                      handleChangePayload(value, "start_date")
                    }
                    handleChangeEndDate={value =>
                      handleChangePayload(value, "end_date")
                    }
                    width="100%"
                    placeholder="Usage Period"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {isOverseas && isForeignCurrency && (
                  <div>
                    <FormLabel label="Exchange Rate" required />
                    <CurrencyNumberInput
                      placeholder="Exchange Rate"
                      value={payload?.exchange_rate}
                      startAdornment="Rp"
                      onChange={value =>
                        handleChangePayload(value, "exchange_rate")
                      }
                    />
                  </div>
                )}
              </Grid>
              {isOverseas && (
                <Grid item xs={12} md={6}>
                  <FormLabel label="Job No" required />
                  <TextInput
                    placeholder="Job No"
                    value={payload?.job_number}
                    onChange={event =>
                      handleChangePayload(event?.target?.value, "job_number")
                    }
                  />
                </Grid>
              )}
              {isOverseas && (
                <Grid item xs={12} md={6}>
                  <FormLabel label="Account Number" required />
                  <SingleFormLayout
                    type="bankSelect"
                    selectionsList={optionBankAccount}
                    selectionKey="account_number"
                    selectionImage="bank.profile_image"
                    selectionLabel="bank.name"
                    value={payload?.account_number}
                    onChange={event =>
                      handleChangePayload(
                        event?.target?.value,
                        "account_number"
                      )
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography className={classes?.formTitle}>
                      Song Detail
                    </Typography>
                  </Grid>
                  <Grid item>
                    {isUsageInvoice && (
                      <Grid
                        container
                        justifyContent="flex-end"
                        columnSpacing={1}
                      >
                        {buttonAddList?.map((item, index) => (
                          <Grid item key={index}>
                            <PrimaryButton
                              label={item?.label}
                              onClick={item?.onClick}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} my={1}>
                <InnoTableV2
                  columns={columnTableSongDetail}
                  items={
                    isUsageInvoice ? payload?.songs : [dataTableClaim] || []
                  }
                  isLoading={false}
                />
              </Grid>
              <Grid item xx={12} mb={1}>
                <SwitchFormLayout
                  isChecked={showTaxes}
                  onChange={() => setShowTaxes(!showTaxes)}
                  label="Use taxes in this transaction"
                />
              </Grid>
              <Grid item xs={12}>
                {showTaxes ? (
                  <Box>
                    <InnoTableV2
                      columns={columnTableTaxes}
                      items={selectedDSP?.taxes}
                      isLoading={false}
                      isHaveAction
                      renderAction={item => RenderTableCheckbox(item)}
                    />
                    <Card className={classes?.taxesContainer}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>Fee</Grid>
                        <Grid item>
                          <CurrencyFormatted
                            value={totalFee}
                            currencyCode={selectedCurrencyCode}
                          />
                        </Grid>
                      </Grid>
                      <div className={classes?.buttonGridContainer}>
                        {checkSelectedTaxes &&
                          selectedTaxes?.map((item, index) => (
                            <Grid
                              container
                              key={index}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>{item?.code}</Grid>
                              <Grid item>
                                <CurrencyFormatted
                                  value={calculateSingleTax(item, totalFee)}
                                  currencyCode={selectedCurrencyCode}
                                />
                              </Grid>
                            </Grid>
                          ))}
                      </div>
                      <Divider className={classes?.divider} />
                      {isOverseas && isForeignCurrency && (
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>Exchange Rate</Grid>
                          <Grid item>
                            <CurrencyFormatted
                              value={payload?.exchange_rate}
                              currencyCode="Rp "
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography className={classes?.currencyLabel}>
                            Total Fee
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CurrencyFormatted
                            value={totalRevenueAfterTax}
                            currencyCode={selectedCurrencyCode}
                            isTotal
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                ) : (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Typography className={classes?.totalFeeLabel}>
                        Total Fee :
                      </Typography>
                      <CurrencyFormatted
                        value={totalRevenueAfterTax}
                        currencyCode={selectedCurrencyCode}
                        isTotal
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {buttonPageActionList?.map((item, index) => (
                    <Grid item key={index}>
                      {item?.secondary ? (
                        <SecondaryButton
                          label={item?.label}
                          onClick={item?.onClick}
                        />
                      ) : (
                        <PrimaryButton
                          label={item?.label}
                          onClick={item?.onClick}
                          disabled={item?.disabled}
                          loading={item?.loading}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <ModalBulkAdd
            open={showModalBulkAdd}
            onClose={handleCloseModalBulkAdd}
            importClasses={classes}
            onSubmit={postUploadDocuments}
          />
          <ModalAddSong
            open={showModalAddSong}
            onClose={handleCloseModalAddSong}
            importClasses={classes}
            onSubmit={handleAddSong}
            preload={payload?.songs}
          />
          <ModalSongResults
            open={showModalSongResults}
            onClose={() => setShowModalSongResult(false)}
            preload={uploadedSong}
            importClasses={classes}
          />
          <ModalUploadResults
            open={showModalUploadResults}
            onClose={() => setShowModalUploadResults(false)}
            preload={uploadedSong}
            importClasses={classes}
          />
        </Container>
      )}
    </Page>
  );
}
const formatPreloadDate = date => moment(date, "DD MMMM YYYY");
const formatPayloadPeriodClaim = date => date.format("YYYY-MM");
const formatPayloadDate = date => date.format("YYYY-MM-DD");
const calculateSingleTax = (tax, totalRevenue) =>
  (tax?.rate / 100) * (tax?.tax_bases / 100) * Number(totalRevenue || 0);
const calculateTotalRevenue = songs => {
  return songs?.reduce((acc, song) => {
    const songTotal = song?.qty * song?.revenue;
    return acc + songTotal;
  }, 0);
};
const calculateTotalTax = (taxes, totalRevenue) => {
  return taxes?.reduce((total, tax) => {
    const afterCountTax =
      (tax.rate / 100) * (tax.tax_bases / 100) * Number(totalRevenue || 0);
    return tax.method === "addition"
      ? total + afterCountTax
      : total - afterCountTax;
  }, 0);
};
const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
const breadcrumbData = pageTitle => [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/invoice",
  },
  {
    label: pageTitle,
    active: true,
  },
];
const columnTableTaxes = [
  {
    name: "code",
    title: "Code",
  },
  {
    name: "description",
    title: "Description",
  },
  {
    name: "rate",
    title: "Rate",
    renderText: item => `${item} %`,
  },
];

export default FormInvoice;
