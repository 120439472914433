import { Box, Grid, styled, Typography } from "@mui/material";
import {
  FormLabel,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
} from "components";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";

const ContractDetailForm = ({ pageDetail, loadingPage }) => {
  const { contract, songs } = pageDetail;
  const selectedSong = songs?.[0];

  return loadingPage ? (
    <SkeletonComponent variant="wave" />
  ) : (
    <Box>
      <Grid container direction="column" rowSpacing={2}>
        <Grid item>
          <SectionLabel
            title="Contract Information"
            subTitle="Contract information that has been made."
          />
          <FormCard>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Contract Number" />
                <Typography>{contract?.contract_number || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Contract Status" />
                <StatusChip
                  type={contract?.is_active_flag && "success"}
                  label={contract?.is_active_flag ? "Active" : "Inactive"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Start Date" />
                <Typography>{getPreloadDate(contract?.start_date)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="End Date" />
                <Typography>{getPreloadDate(contract?.end_date)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Publisher" />
                <Typography>{contract?.publisher || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Publisher ID" />
                <Typography>{contract?.id_publisher || "-"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Description" />
                <Typography>{contract?.description || "-"}</Typography>
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
        <Grid item>
          <SectionLabel
            title="Song Information"
            subTitle="Detail song information."
          />
          <FormCard>
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={6}>
                <FormLabel label="Title" />
                <Typography>{selectedSong?.title || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="ISWC Code" />
                <Typography>{selectedSong?.iswc_code || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="Ownership" />
                <Typography>{selectedSong?.ownership || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormLabel label="Performer" />
                <Typography>{selectedSong?.performer || "-"}</Typography>
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
        <Grid item>
          <SectionLabel
            title="Composer/Author"
            subTitle="Detail composer/author information."
            mb="16px"
          />
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={selectedSong?.composers || []}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const getPreloadDate = date =>
  date ? moment(date, "DD-MM-YYYY").format("DD MMM YYYY") : "-";
const FormCard = styled(Box)({
  border: "1px solid #ebebeb",
  borderRadius: "5px",
  padding: "24px",
  marginTop: "16px",
});
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author Name",
    renderText: item => item || "-",
  },
  {
    name: "iswc_code",
    title: "ISWC Code",
  },
  {
    name: "ownership_percentage",
    title: "Ownership Percentage",
  },
];

export default ContractDetailForm;
